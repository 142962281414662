
    
    
    import icLogoFull from './images/ic_logo_full.png';
import ilBannerBackground from './images/il_banner_background.png';
import ilBottomLeft from './images/il_bottom_left.png';
import ilBottomRight from './images/il_bottom_right.png';
import ilLogoFull from './images/il_logo_full.png';
import ilShop from './images/il_shop.png';
import ilTopLeft from './images/il_top_left.png';
import ilTopRight from './images/il_top_right.png';

		const images = {
      dark: {},
      light: {},
      default: {icLogoFull,ilBannerBackground,ilBottomLeft,ilBottomRight,ilLogoFull,ilShop,ilTopLeft,ilTopRight},
    };

    export default images;
  
import React from "react";
import { Navigate, Route, Routes as ReactRoutes } from "react-router-dom";
import HomePage from "./pages/HomePage";

const Routes = () => {
  return (
    <ReactRoutes>
      <Route path="/" element={<HomePage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </ReactRoutes>
  );
};

export default Routes;

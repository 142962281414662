import React, { useRef } from "react";
import { Link } from "react-router-dom";
import R from "../../res";
import classes from "./index.module.scss";

const HomePage = () => {
  const links = React.useMemo(
    () => [
      { label: "home", to: "/" },
      { label: "faq", to: "/faq" },
      { label: "features", to: "/features" },
      { label: "contact", to: "/contact" },
    ],
    []
  );

  return (
    <div className={classes["container"]}>
      <div className={classes["banner"]}>
        <img
          src={R.images.default.ilTopLeft}
          className={classes["top-left-illustration"]}
          alt="top-left-illustration"
        />
        <img
          src={R.images.default.ilTopRight}
          className={classes["top-right-illustration"]}
          alt="top-right-illustration"
        />
        <img
          src={R.images.default.ilBottomLeft}
          className={classes["bottom-left-illustration"]}
          alt="bottom-left-illustration"
        />
        <img
          src={R.images.default.ilBottomRight}
          className={classes["bottom-right-illustration"]}
          alt="bottom-right-illustration"
        />
        <img
          src={R.images.default.ilBannerBackground}
          className={classes["background"]}
          alt="background"
        />
        <div className={classes["header"]}>
          <img
            src={R.images.default.icLogoFull}
            alt="logo"
            className={classes["logo"]}
          />
          <div className={classes["links"]}>
            {links.map(({ label, to }, index) => {
              return (
                <Link to={to} className={classes["link"]} key={index}>
                  <p>{label}</p>
                </Link>
              );
            })}
          </div>
          <div className={classes["row"]}>
            <button className={classes["button"]}>
              <p>download ios</p>
            </button>
            <button className={classes["button"]}>
              <p>download android</p>
            </button>
          </div>
        </div>
        <p className={classes["label"]}>Ultimate Dispensaries Tool</p>
        <p className={classes["title"]}>
          Reach more customers easier than ever before.
        </p>
        <p className={classes["label-1"]}>
          Use our all new management app and start selling easier than before.
        </p>
        <div className={classes["row-buttons"]}>
          <button className={classes["button"]}>
            <p>Earn More →</p>
          </button>
          <button className={classes["button"]}>
            <p>Learn More ↓</p>
          </button>
        </div>
        <img
          src={R.images.default.ilShop}
          alt="illustration"
          className={classes["illustration"]}
        />
      </div>
      {Array(10)
        .fill("w")
        .map((_, index) => (
          <h1 key={index}>ok</h1>
        ))}
    </div>
  );
};

export default HomePage;
